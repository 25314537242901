import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Cta from './cta'

const AboutComponent = () => {
    const ctaConfig = [
        { title: 'See Locations', to: '/locations' },
        { title: 'How to Volunteer', to: '/volunteer' }
    ]
    
    return (
        <div id="about">
            <div className="grid md:grid-cols-3 gap-4 pt-5 md:mt-20">
                <div className="md:col-span-1 order-2 md:order-1">
                    <h2 className="md:text-right mb-10">Volunteer 2-3hrs <div className="text-2xl text-black">for 6 weeks</div></h2>
                    <p>What we do is turn up every six weeks for 2 -3 
                    hours and offer free haircuts, providing dignity and 
                    respect for those in need.</p>
                    <p>To do this, Hair Aid is always seeking passionate 
                    and caring hairdressers who are able to commit to 
                    2 - 3 hours every 6 weeks in their own local 
                    community.</p>
                </div>
                <div className="md:col-span-2 order-1 md:order-2">
                    <StaticImage
                      src="../images/hairdresser.png"
                      width={700}
                      quality={100}
                      formats={["AUTO", "WEBP", "AVIF"]}
                      placeholder="blurred"
                      alt="Hair Dresser"
                      className="md:ml-20"
                    />
                </div>
            </div>
            <div className="grid md:grid-cols-3 gap-4">
                <div className="col-span-2">
                    <StaticImage
                      src="../images/buddies.png"
                      width={1000}
                      quality={100}
                      formats={["AUTO", "WEBP", "AVIF"]}
                      placeholder="blurred"
                      alt="Hair Dresser"
                      className="mr-20 w-full"
                    />
                </div>
                <div className="col-span-1 md:flex md:items-center md:self-center md:flex-wrap">
                    <h2 className="md:text-left mb-10">Who we work with</h2>
                    <p>We work with community groups, charities, government organisations and churches that support homeless, domestic violence sufferers, migrants or those just in suffering at a time of crisis.</p>
                </div>
            </div>
            <Cta config={ctaConfig} />
        </div>
    )
}

export default AboutComponent