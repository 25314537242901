/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import styled from 'styled-components'
import { useStaticQuery, graphql } from "gatsby"
import "../css/index.scss"
import Header from "./header"
import Footer from "./footer"

const Layout = ({ children }) => {
    const data = useStaticQuery(graphql `
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }`)

    const Main = styled.div`
        max-width: 980px;
        margin: 0 auto;
    `

    return (
        <>
        <div style={{overflow:`hidden`}}>
        <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
        <Main>{children}</Main>
        <Footer />
        </div>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout