import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import About from "../components/about"

const IndexPage = () => (
    <Layout>
        <Seo title="Community Cuts" />
        <div className="container md:text-left">
            <h1>Hair Aid <br /> Community Cuts</h1>
            <About />
        </div>
    </Layout>
)

export default IndexPage