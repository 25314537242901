import React, { useState } from "react"
import { Squash as Hamburger } from 'hamburger-react'
import styled from 'styled-components'
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Shape from './shape.js'
import Logo from '../svg/logo.svg'

const Header = ({ siteTitle }) => {
    const [navVisible, setNavVisible] = useState(false);
    
    const links = [{
        title: 'Home',
        href: 'https://hairaid.org.au'
    }, {
        title: 'About',
        href: '/'
    }, {
        title: 'Locations',
        href: '/locations'
    }, {
        title: 'Volunteer',
        href: '/volunteer'
    }]

    const NavItems = styled.div `
        background: #c22765;
        position: relative;
        z-index: 10;
    `
    return (
        <>
        <header className="relative">
            <Shape styles={`rotate-45 -left-24 md:-left-60 -top-24 w-48 md:w-96 h-48 md:h-96`}/>
            <nav className="py-2 md:py-10 md:mb-14">
                <div className="container max-w-7xl mx-auto md:flex md:items-center">
                    <div className="flex justify-between items-center">
                        <Link to="/" className="font-bold text-xl text-indigo-600">
                            <div id="logo">
                                <Logo alt="logo" title="logo" className="relative z-20" />
                            </div>
                        </Link>
                       <div id="hamburger" className="md:hidden">
                            <Hamburger
                               toggled={navVisible} 
                               toggle={setNavVisible} 
                               hideOutline={true}
                               duration={0}
                            />
                       </div>
                    </div>
                    <NavItems className={`md:flex flex-col md:flex-row md:ml-auto mt-3 md:mt-0 text-2xl rounded ${navVisible ? 'flex' : 'hidden'}`} id="navbarCollapse">
                        {links.map((link, index) => {
                            return <Link key="{index}" to={link.href} className="p-2 lg:px-6 text-white text-center hover:bg-indigo-100 hover:text-indigo-700 transition-colors duration-300">
                            {link.title}
                            </Link>
                        })}
                    </NavItems>
                </div>
            </nav>
        </header>
        </>
    )
}

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}

export default Header