import * as React from 'react'
import PropTypes from "prop-types"

const Shape = ({styles}) => {
    return (
        <span className={`shape inline-block transform absolute z-0 ${styles ? styles : null}`} style={{background:`#1795D3`}}></span>
    )
}

Shape.propTypes = {
    styles: PropTypes.string,
}

export default Shape