import React from 'react'
import PropTypes from "prop-types"
import { Link } from "gatsby"

const Cta = ({config}) => {
    return(
        <div className="grid grid-cols-2 py-10 text-2xl relative z-10 text-center">
            <div className="col-span-2 md:col-span-1">
                <Link to={config[0].to} style={{background:`#1795d3`}} 
                className="p-4 md:rounded-tl-2xl md:rounded-bl-2xl w-full md:w-96 block md:float-right">{config[0].title}</Link>
            </div>
            <div className="col-span-2 md:col-span-1">
                <Link to={config[1].to} style={{background:`#000`}} 
                className="p-4 md:rounded-tr-2xl md:rounded-br-2xl w-full md:w-96 block">{config[1].title}</Link>
            </div>
        </div>
    )
}

Cta.propTypes = {
    config: PropTypes.array,
}

export default Cta