import * as React from 'react'
import Shape from './shape.js'

const footer = () => {
    return (
        <footer className="md:pt-40 pb-40 text-center">
            <p className="text-2xl">&copy;{new Date().getFullYear()} Hair Aid</p>
            <p><a href="https://hairaid.org.au">hairaid.org.au</a></p>
            <Shape styles={`rotate-45 -right-24 md:-right-60 bottom-0 w-48 md:w-96 h-48 md:h-96`} />
        </footer>
    )
}

export default footer